import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Button, H3, H5, Grid, H2, Row } from "@maggioli-design-system/react";
import { downloadDocument, getDocuments } from "../../services/Redazionale";
import LoginForm from "../../components/LoginForm/LoginForm";
import { IHandleLoader } from "../../interfaces/IHandleLoader";
import { IDocument } from "../../interfaces/IDocument";
import { checkAccess, getCookie, getProduct, productExists } from "../../utils/Constants";
import { checkUserAuthorization } from "../../services/Approfondimenti";
import { IUserRole } from "../../App";
import DOMPurify from "dompurify";

interface Props {
    handleLoader: IHandleLoader;
    user: IUserRole;
}

const Article = ({ handleLoader, user }: Props) => {
    const history = useNavigate();
    const { id, idProdotto } = useParams<Record<string, string>>();
    const loggedUser = JSON.parse(getCookie("loggedUser"));

    const [doc, setDoc] = useState<IDocument>(null);
    const [docHtml, setDocHtml] = useState<string>("");
    const [approAuth, setApproAuth] = useState<boolean>(false);
    const [subAuth, setSubAuth] = useState<boolean>(false);

    const isLogged: boolean = loggedUser !== null || getCookie("refresh_token") !== null;

    useEffect(() => {
        isLogged && loadDocument();
        checkAuthorization();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadDocument = async () => {
        handleLoader.setShowLoader(true);
        const res = await downloadDocument(parseInt(id));
        const res2 = await getDocuments(parseInt(id));
        handleLoader.setShowLoader(false);
        if (res && res2) {
            setDoc(res2);
            setDocHtml(await res.text());
        }
    };

    // const checkApproAuthorization = async () => {
    //     if (!isAppro || !productExists(idProdotto)) {
    //         return;
    //     }
    //     const res = await checkUserAuthorization(loggedUser.id, getProduct(idProdotto).isbn);
    //     setApproAuth(res.status);
    // };


    const checkAuthorization = async () => {
        if (!productExists(idProdotto)) {
            return;
        }
        if (loggedUser === null) {
            return;
        }

        const isAppro: boolean = loggedUser.type === "approfondimenti";
        if(isAppro) {
            const res = await checkUserAuthorization(loggedUser.id, getProduct(idProdotto).isbn);
            setApproAuth(res.status || user.isWorker);
        } else {
            const res2 = await checkAccess(id);
            setSubAuth(res2);
        }
    };

    if (!isLogged) {
        return (
            <Grid columns="3" className="view-limit bg-adjust-tone-19 p-5 my-5 mobile:pt-0">
                <H2>Accedi</H2>
                <LoginForm />
            </Grid>
        );
    }

    if (doc === null) {
        return <div></div>;
    }

    if (!(approAuth || subAuth)) {
        return (
            <div className="mx-20 py-12">
                <H3 className="text-center">Non è possibile accedere al contenuto</H3>
                <Button icon="action-back" className="mx-auto mt-5" disabled={history.length < 3} onClick={() => history(-1)}>
                    Indietro
                </Button>
            </div>
        );
    }

    return (
        <div className="mx-20 tablet-max:mx-8 py-12">
            <Row lastChild="to-right">
                <Button icon="action-back" disabled={history.length < 3} onClick={() => history(-1)}>
                    Indietro
                </Button>
            </Row>
            <Card className="my-8 shadow  m-auto">
                <H3>{doc.descrizione}</H3>
                <H5>{doc.titolo}</H5>
                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(docHtml) }} />
            </Card>
        </div>
    );
};

export default Article;
